import React from "react"
import {Helmet} from "react-helmet";
import { Link } from 'gatsby'
import { getBackLink } from '../utilities.js'

import Layout from "../components/_layout";

export default function FourOhFour({ data, pageContext }) {
  return <Layout>
    <Helmet>
      <title>404</title>
    </Helmet>
    <div className="container">
      <div className="row">
        <h2 className="col-12 text-center four-oh-four-header">404</h2>
        <div className="col-12">
          <p className="four-oh-four-message">I don't know what you're looking for, sorry I can't help.</p>
        </div>
        <div class="col-12 text-center four-oh-four-button">
          <Link className="btn btn-dark" id="back-link" to={getBackLink()}>
            <i class="bi bi-arrow-bar-up"></i>&nbsp; View Main Listing
          </Link>
        </div>
      </div>
    </div>
  </Layout>
}
